import React from "react";

import "../WhyFlutter/WhyFlutter.scss";

import * as Icons from '../icons';

import heroImage from "../../../assets/imgs/services/flutter-dev-background.png";

import {SectionTitle} from "../shared/SectionTitle/SectionTitle";
import {IconVariants, ItemsGrid} from "../shared/ItemsGrid/ItemsGrid";
import {Intro} from "../shared/Intro/Intro";
import {Hero} from "../shared/Hero/Hero";
import {OurApplications} from "../shared/OurApplications/OurApplications";
import {TwoColumnsContent, TwoColumnsContentVariants} from "../shared/TwoColumnsContent/TwoColumnsContent";
import {TestimonialSlider} from "../../TestimonialSlider/TestimonialSlider";
import {ContactUs} from "../shared/ContactUs/ContactUs";
import {Process} from "../shared/Process/Process";
import {ServicesBlogPosts} from "../shared/ServicesBlogPosts/ServicesBlogPosts";
import {ContinueReading} from "../../ContinueReading/ContinueReading";

export const MobileApps = ({posts}) => (
        <div className={"why-flutter"}>
            <Hero title={<h1>Your <span>business</span>, at your customer’s fingertips!</h1>} subtitle={<>Modern mobile applications with a perfect fit <br/> Want an app your customers will love? Let’s talk.</>} heroImage={heroImage}/>
            <Intro
                sectionTitle={<SectionTitle number={1} title={"Build mobile applications with us!"} isBorderBottom={true}/>}
                leftColumn={[
                    <div className={"content"}>At AllBright.io, we help our clients create <b>excellent mobile applications.</b></div>,
                    <div className={"content"}>We'll be with you every step of the way. We help you translate your <b>concepts into product</b> specifications.</div>,
                    <div className={"icon"}><Icons.GreyOctagon/><Icons.Code/></div>,
                    <div className={"graphic"}><Icons.PersonAtDesk/></div>
                ]}
                rightColumn={[
                    <div className={"content"}>If needed, we can create a fast prototype to show your board of directors or investors or test it with your first clients. We establish a production and publishing plan to ensure that project collaboration is as effective as possible. We take care of all technical issues. Our UX/UI team studies user behaviour and the market climate. Then they develop beautiful and focused interfaces, making it natural for customers to adopt the app in their lives.</div>,
                    <div className={"content"}>Feel safe about app quality and budget too. We always make sure that the solution proposed fits both your business and your wallet.</div>,
                    <div className={"content"}>We understand the importance of prioritization. We strive to strike the right balance between making the best product, minimizing time to market, and controlling costs, with the most critical and cost-effective features arriving first.</div>,
                    <div className={"content"}>Every product evolves over time, so it's wise to design and build them with future growth in mind. AllBright.io ensures scalability and app maintenance are both achievable without unforeseen problems.</div>,
                    <div className={"content"}>We also ensure that your product is app store optimised so that it can enter the market with the highest chance of success.</div>,
                    <div className={"content"}>We’ve developed mobile products for organisations ranging from Fortune 500 corporations to mid-size companies and Kickstarter startups. We bring all the specialist knowledge and experience to your project.</div>,
                    <div className={"content"} style={{marginBottom: "20px"}}>Our clients:</div>,
                    <div className={"rectangle"}>Startups</div>,
                    <div className={"rectangle"} style={{marginLeft: '5px'}}>Scale Ups</div>,
                    <div className={"graphic-mobile"}><Icons.PersonAtDesk/></div>
                ]}
            />
            <ItemsGrid
                items={[
                    {'title': 'Know your customers', 'desc': 'Examine customer expectations. Market research will reveal what they want, the most critical features, and how you can make your app more impactful than the competition.', 'icon': <Icons.PersonIdRed/>},
                    {'title': 'Great product designt', 'desc': 'Designing great products means solving your customers\' problems while meeting your business needs. Is your app engaging for your customers, do they enjoy or benefit from it, is the UX excellent and attractive?', 'icon': <div style={{paddingTop: '6px'}}><Icons.DetailedEyeRed/></div>},
                    {'title': 'Development strategy', 'desc': 'We advise publishing early and iterating quickly. Priorities are determined by business requirements, market maturity, implementation complexity, and time and budget constraints.', 'icon': <Icons.CodeRed/>},
                    {'title': 'Prepare for publication', 'desc': 'When publishing an app, App Store Optimisation (ASO) is an often-missed step. We help leverage recognition and popularity for your app in the app store.', 'icon': <div style={{paddingTop: '3px', paddingLeft: '5px'}}><Icons.WindowUpRed/></div>},
                    {'title': 'Product monitoring and support', 'desc': 'After release, you need a professional team ready to react when customers want changes or for new OS and device updates. We make certain that your software runs smoothly at all times!', 'icon': <div style={{paddingTop: '3px', paddingLeft: '5px'}}><Icons.WindowSearchRed/></div>},
                    {'title': 'Audit your mobile app code', 'desc': 'Want to check the quality of your app? Learn whether it conforms to development standards, locate weak areas and verify security.', 'icon': <Icons.PhoneMobileAppsRed/>}
                ]}
                sectionTitle={<SectionTitle number={2} title={"How we can help you"}/>}
                iconVariant={IconVariants.CIRCLE}
            />
            <OurApplications sectionTitle={<SectionTitle number={3} title={"Our Flutter applications"} isBorderBottom={true}/>}/>
            <ItemsGrid
                items={[
                    {'title': 'get boosted', 'desc': 'Raise your profile and boost your brand’s accessibility', 'icon': <Icons.FlagTypeThing/>},
                    {'title': 'love brands', 'desc': 'Let customers love your brand even more', 'icon': <Icons.Hearts/>},
                    {'title': 'increase loyalty', 'desc': 'Solve customer problems, grow engagement and increase loyalty', 'icon': <Icons.PersonHeart/>},
                    {'title': 'earn more money', 'desc': 'Open up new sources of revenue', 'icon': <Icons.Coins/>},
                    {'title': 'growth', 'desc': 'Create the potential for future growth ', 'icon': <Icons.ChartUp/>},
                    {'title': 'meet customers', 'desc': 'Understand customer needs and meet them, fast!', 'icon': <Icons.Person/>},
                ]}
                sectionTitle={<SectionTitle number={4} title={"Why build a mobile app?"}/>}
                iconVariant={IconVariants.OCTAGON}
            />
            <div className={"white-bg"} style={{paddingTop: '150px'}}>
                <div className={"sections tech"}>
                    {[{title: 'Cross platform app development', subtitle: 'Flutter'},
                      {title: 'Native apps development', subtitle: 'Kotlin / Swift icons or Apple Android'},
                      {title: 'Mobile backend development', subtitle: 'node.js, django'}].map(x => {
                        return <div className={"rectangle"}>
                            <div className={"title"}>{x.title}</div>
                            <div className={"subtitle"}>({x.subtitle})</div>
                        </div>
                    })}
                </div>
            </div>
            <TwoColumnsContent
                sectionTitle={<SectionTitle title={'The types of projects we work on'} number={5} isBorderBottom={true}/>}
                variant={TwoColumnsContentVariants.WHITE}
                items={[
                    {title: 'Interactive prototypes', content: 'Build a clickable mobile app prototype in under two weeks. This saves time and budget, as well as allowing you to confirm technical viability and get input from customers and investors earlier.', image: <Icons.PersonAndChart2/>},
                    {title: 'MVP (Minimum Viable Product)', content: 'Take the fast road to a cost-effective MVP. Market validations can begin sooner and happen more frequently, and you\'ll be in a great place for future growth.', image: <Icons.TwoPhones/>},
                    {title: 'Long-term projects', content: 'An end-to-end service that enables you to create a scalable product. Ideas, design, production, release, maintenance, and further development are the stages covered.', image: <Icons.Browser/>},
                ]}
            />
            <div className={"white-bg"} style={{paddingTop: '150px'}}>
                <div className={"sections tech stack"}>
                    {[{title: 'Phone apps'}, {title: 'Tablet apps'}, {title: 'Wearable apps'}, {title: 'IoT'}].map(x => {
                        return <div className={"rectangle"}>
                            <div className={"title"}>{x.title}</div>
                        </div>
                    })}
                </div>
            </div>
            <ItemsGrid
                items={[
                    {'title': 'Understand your product’s business goals, customers and competitors', 'desc': '', 'icon': <Icons.SmallBriefCaseRed/>},
                    {'title': 'Beautiful interface and excellent user experience\n', 'desc': '', 'icon': <div style={{paddingTop: '6px'}}><Icons.DetailedEyeRed/></div>},
                    {'title': 'Reduced complexity, lower costs for development and maintenance', 'desc': '', 'icon': <Icons.HandRed/>},
                    {'title': 'Great performance and scalabity', 'desc': '', 'icon': <Icons.FourWindowsRed/>},
                    {'title': 'Security', 'desc': '', 'icon': <Icons.LockRed/>},
                    {'title': 'App Store Optimisation', 'desc': '', 'icon': <Icons.CoinsRedCheck/>},
                ]}
                sectionTitle={<SectionTitle number={6} title={"The key aspects we focus on"}/>}
                iconVariant={IconVariants.WHITE_CIRCLE}
            />
            <section className="testimonial-slider white-bg color-black border-grey" style={{paddingTop: "100px"}}>
                <div className={"sections"}>
                    <TestimonialSlider page="index" title={"Our team about mobile apps"}/>
                </div>
            </section>
            <ContactUs title={"Need an effective mobile app? Get in touch with us for a free expert consultation."} buttonText={"Contact us"}/>
            <div className={"white-bg why-allbright"}>
                <div className={"sections"} style={{paddingTop: "50px"}}>
                    <SectionTitle number={7} title={"Why Allbright"}/>
                    <div className={"row"}>
                        <div className={"column left"}>
                            <div className={"content"}>We've been developing mobile applications <b>for 15 years.</b></div>
                        </div>
                        <div className={"column right"}>
                            <div className={"content"}>Our story began in the early 2000s when we built Symbian systems. We then created native mobile applications for Android, iOS, and Windows devices - before transitioning to the best cross-platform technologies available today.</div>
                            <div className={"content"}>In that time, we built products for businesses, small to large, across many different sectors. We gained a great understanding of mobile products and how to create apps that meet business goals and add value.</div>
                        </div>
                    </div>
                </div>
            </div>
            <ItemsGrid
                items={[
                    {'title': '15 years', 'desc': 'of commercial experience', 'icon': <Icons.BriefcaseRed/>},
                    {'title': '12 years', 'desc': 'building scalable applications with millions of users', 'icon': <Icons.PeopleNetworkRed/>},
                    {'title': '6 years', 'desc': 'experience in secure fintech projects', 'icon': <Icons.BooksRed/>},
                    {'title': '4 years', 'desc': 'developing medical and health applications', 'icon': <Icons.HeartRed/>},
                    {'title': 'PCI DSS / HIPAA', 'desc': 'Experience with GDPR, PCI DSS and HIPAA security compliance', 'icon': <Icons.GlobusRed/>},
                    {'title': 'mentoring', 'desc': 'Built mobile applications for big corporations and brand-new startups', 'icon': <Icons.HandsRed/>},
                    {'title': 'Details', 'desc': 'Great attention to details', 'icon': <div style={{paddingTop: '6px'}}><Icons.EyeRed/></div>},
                    {'title': 'Product team', 'desc': 'UX and UI matters to us', 'icon': <Icons.PencilRed/>},
                    {'title': '10 days', 'desc': 'Have a rapid prototype built in as little as 10 days!', 'icon': <Icons.WindowsRed/>},
                ]}
                sectionTitle={null}
                iconVariant={IconVariants.OCTAGON_TRANSPARENT}
            />
            <Process sectionTitle={<SectionTitle number={8} title={"Development process"}/>}/>
            <section className="testimonial-slider white-bg color-black border-grey">
                <div className={"sections"}>
                    <TestimonialSlider page="index" title={"Clients about us"}/>
                </div>
            </section>
            <ContactUs title={"Want to talk with a mobile app expert? Contact us!"} buttonText={"Get in touch"}/>
            <ServicesBlogPosts posts={posts}/>
            <div className={"sections"} style={{paddingBottom: "0"}}>
                <ContinueReading nextSection="React Native" link="React Native" />
            </div>
        </div>
);